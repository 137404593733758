import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 48;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>カリフォルニアへのお土産</li>
        <li>100ドルのチョコレートの盗難話</li>
        <li>
          <a
            href="https://www.fineandraw.com/collections/truffles/products/truffle-gift-set-16-piece"
            target="_blank"
            rel="noopener noreferrer"
          >
            FINE & RAW Chocolate
          </a>
        </li>

        <li>ローチョコレートを初めて食べてみた</li>
        <li>プクーっと膨らむ新鮮なコーヒー</li>
        <li>
          <a
            href="https://misawacoffee.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            長野の珈琲焙煎店 三澤珈琲
          </a>
        </li>

        <li>最近ホットなニューヨークのコーヒーブランドを探す</li>
        <li>
          <a
            href="https://www.devocion.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Devoción Coffee
          </a>
        </li>

        <li>
          <a
            href="https://www.devocion.com/pages/fresh-coffee-timeline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Green Date
          </a>
        </li>

        <li>産地、農園、品種、品質管理など豆へのこだわり</li>
        <li>ブレンドorシングルオリジン</li>
        <li>サードウェーブからフィフスウェーブコーヒーへ</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
